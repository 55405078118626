.wrapper {
  -webkit-touch-callout: none;
  -moz-user-select: none; /*火狐*/
  -webkit-user-select: none; /*webkit浏览器*/
  -ms-user-select: none; /*IE10*/
  -khtml-user-select: none; /*早期浏览器*/
  user-select: none;
  position: relative;
  display: flex;
  cursor: pointer;
}

.card {
  box-shadow: 20px 20px 30px 15px rgba(60, 224, 121, 0.3);
  transition: box-shadow 0.5s;
  will-change: transform;
  width: 200px;
  height: 200px;
  border-radius: 100px;
  @media screen and (max-width: 500px) {
    width: 160px;
    height: 160px;
    border-radius: 80px;
    box-shadow: 10px 10px 15px 8px rgba(60, 224, 121, 0.3);
  }

  display: flex;
  align-items: center;
  justify-content: center;
  background: #3ce079;
}

.dropFilter {
  width: 200px;
  height: 200px;
  border-radius: 100px;
  @media screen and (max-width: 500px) {
    width: 160px;
    height: 160px;
    border-radius: 80px;
    left: 90px;
  }
  position: absolute;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(20px);
  left: 110px;
  border: 0.5px solid rgba(255, 255, 255, 0.5);
}

.text {
  pointer-events: none;
  color: white;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  & > h3 {
    color: white;
    font-weight: 700;
  }
}
