.root {
  width: 100vw;
  height: 100vh;
  position: relative;
  padding: 24px;
  display: flex;
  flex-direction: column;
}

.barWrapper {
  display: flex;
  padding: 5px 24px;
  align-items: center;
  justify-content: space-between;
  flex: 0 1 auto;
}

.wrapper {
  flex: 1 1 auto;
  padding: 5px 24px;
}
