.root {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 24px;
}

.tableWrapper {
  padding: 10px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.smallTag {
  margin: 4px;
}

.barWrapper {
  flex: 0 1 auto;
  @media screen and (max-width: 500px) {
    flex-direction: column;
    row-gap: 20px;
    margin-top: 20px;
  }
  display: flex;
  padding: 5px 24px;
  align-items: center;
  justify-content: space-between;
}
