.card {
  -webkit-touch-callout: none;
  -moz-user-select: none; /*火狐*/
  -webkit-user-select: none; /*webkit浏览器*/
  -ms-user-select: none; /*IE10*/
  -khtml-user-select: none; /*早期浏览器*/
  user-select: none;
}
.yellow {
  box-shadow: 20px 20px 30px 15px rgba(255, 207, 93, 0.3);
  @media screen and (max-width: 500px) {
    box-shadow: 10px 10px 15px 8px rgba(255, 207, 93, 0.3);
  }
  background: #ffcf5d;
}

.red {
  box-shadow: 20px 20px 30px 15px rgba(255, 92, 92, 0.3);
  @media screen and (max-width: 500px) {
    box-shadow: 10px 10px 15px 8px rgba(255, 92, 92, 0.3);
  }
  background: #ff5c5c;
}
.root {
  width: 100vw;
  height: 100vh;
  position: relative;
  padding: 24px;
  display: flex;
  flex-direction: column;
  // align-items: center;
  // justify-content: center;
}

.wrapper {
  display: flex;
  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
  // max-width: 600px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  flex: 1 1 auto;
}
.barWrapper {
  display: flex;
  width: 100%;
  height: 32px;
  padding: 5px 24px;
  align-items: center;
  justify-content: space-between;
  flex: 0 1 auto;
}
