.alert {
  position: absolute;
  top: 10px;
  z-index: 10;
}

.root {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  -webkit-touch-callout: none;
  -moz-user-select: none; /*火狐*/
  -webkit-user-select: none; /*webkit浏览器*/
  -ms-user-select: none; /*IE10*/
  -khtml-user-select: none; /*早期浏览器*/
  user-select: none;
}

.card {
  padding: 24px;
  border-radius: 16px;
  min-width: 270px;
  text-align: center;
}

.bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
