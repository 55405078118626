.root {
  width: 100vw;
  height: 100vh;
  position: relative;
  padding: 24px;
  display: flex;
  flex-direction: column;
}

.barWrapper {
  display: flex;
  padding: 5px 24px;
  align-items: center;
  justify-content: space-between;
  flex: 0 1 auto;
}

.wrapper {
  flex: 1 1 auto;
  padding: 5px 24px;
  display: flex;
  flex-direction: column;
}

.titleWrapper {
  flex: 0 1 auto;
  display: flex;
  width: 100%;
  @media screen and (max-width: 500px) {
    flex-direction: column;
    row-gap: 20px;
  }
  justify-content: space-between;
  align-items: center;
}
.bodyWrapper {
  display: flex;
  margin-top: 10px;
  flex: 1 1 auto;
  @media screen and (max-width: 500px) {
    flex-direction: column;
    row-gap: 20px;
  }
}
.bodyItem {
  flex: 1 1 50%;
  position: relative;
}
.card {
  height: 100%;
  width: 100%;
  border-radius: 16px;
}
.sendButton {
  position: absolute;
  z-index: 1;
  bottom: 20px;
  right: 20px;
}
